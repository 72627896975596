import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Pricing from "../scenes/Pricing";

import {
  IPrismicHeader,
  IPrismicFooter,
  IPrismicPricing,
  IPrismicPackage,
  IPrismicKSP,
} from "../scenes/prismic.model";
import Footer from "../components/Footer";
import { FAVICON } from "../utils/constants";

interface IProps {
  data: {
    prismicHeader: {
      data: IPrismicHeader;
    };
    prismicPricing: {
      data: IPrismicPricing;
    };
    allPrismicPackages: {
      edges: any[];
    };
    allPrismicKeySellingPoints: {
      edges: any[];
    };
    prismicFooter: {
      data: IPrismicFooter;
    };
  };
  pageContext: {
    locale: string;
    name: string;
  };
}

export default function PricingPage(props: IProps) {
  const mapPackages = (): IPrismicPackage[] => {
    return props.data.allPrismicPackages.edges
      .map((edge: any) => edge.node.data)
      .sort((a: IPrismicPackage, b: IPrismicPackage) => {
        return a.ordinal_number - b.ordinal_number;
      });
  };

  const mapKSP = (): IPrismicKSP[] => {
    return props.data.allPrismicKeySellingPoints.edges.map(
      (edge: any) => edge.node.data
    );
  };

  return (
    <>
      <Helmet>
        <title>matchHR</title>
        <link rel="shortcut icon" href={FAVICON} type="image/x-icon" />
      </Helmet>
      <Header
        data={props.data.prismicHeader.data}
        pageContext={props.pageContext}
      />
      <Pricing
        prismicData={props.data.prismicPricing.data}
        packages={mapPackages()}
        keySellingPoints={mapKSP()}
      />
      <Footer data={props.data.prismicFooter.data} />
    </>
  );
}

export const pageQuery = graphql`
  query prismicPricingPageQuery($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      data {
        how_it_works_link {
          text
        }
        pricing_link {
          text
        }
        contact_us_link {
          text
        }
        about_us_link {
          text
        }
        login_link {
          text
        }
        signup_link {
          text
        }
        book_demo_link {
          text
        }
        get_started_link {
          text
        }
      }
    }
    prismicPricing(lang: { eq: $locale }) {
      data {
        pricing_header {
          text
        }
        pricing_label {
          text
        }
        buy_button_label {
          text
        }
        custom_experience {
          text
        }
        contact_us {
          text
        }
      }
    }
    allPrismicPackages(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            ordinal_number
            name {
              text
            }
            explanation {
              text
            }
            price {
              text
            }
            per_assessment {
              text
            }
            illustration {
              url
            }
          }
        }
      }
    }
    allPrismicKeySellingPoints(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            label {
              text
            }
          }
        }
      }
    }
    prismicFooter(lang: { eq: $locale }) {
      data {
        footer_heading {
          text
        }
        footer_label {
          text
        }
        get_started_button {
          text
        }
        copyright {
          text
        }
        contact_us_link {
          text
        }
        privacy_policy_link {
          text
        }
      }
    }
  }
`;
