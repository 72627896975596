import React, { Component } from "react";

import { ConfirmationIcon } from "../../assets/icons";
import { appInsights } from "../../components/AppInsights/AppInsights";
import LocalizedLink from "../../components/LocalizedLink";
import { contactUsPath } from "../../utils/paths";

import {
  IPrismicPricing,
  IPrismicPackage,
  IPrismicKSP,
} from "../prismic.model";

import * as styles from "./Pricing.module.scss";

interface IProps {
  prismicData: IPrismicPricing;
  packages: IPrismicPackage[];
  keySellingPoints: IPrismicKSP[];
}

export default class Pricing extends Component<IProps> {
  componentDidMount() {
    appInsights.trackPageView();
  }

  render() {
    const { prismicData, packages, keySellingPoints } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <p className={styles.heading}>{prismicData.pricing_header.text}</p>
          <p className={styles.label}>{prismicData.pricing_label.text}</p>
        </div>
        <div className={styles.packagesContainer}>
          {packages.map((prismicPackage: IPrismicPackage) => (
            <div key={prismicPackage.ordinal_number} className={styles.package}>
              <p className={styles.packageName}>{prismicPackage.name.text}</p>
              <img
                src={prismicPackage.illustration.url}
                alt={prismicPackage.name.text}
              />
              <p className={styles.packageExplanation}>
                {prismicPackage.explanation.text}
              </p>

              <div className={styles.packagePrice}>
                <p className={styles.packagePriceText}>
                  {prismicPackage.price.text}
                </p>
                <p className={styles.packagePerAssessment}>
                  /{prismicPackage.per_assessment.text}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.keySellingPointsContainer}>
          <div className={styles.keySellingPoints}>
            {keySellingPoints.map((ksp: IPrismicKSP, index: number) => (
              <div key={index} className={styles.keySellingPoint}>
                <ConfirmationIcon />
                <p className={styles.label}>{ksp.label.text}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.buyLinkContainer}>
          <a className={styles.buyLink} href={`${process.env.HT_APP_BUY_URL}`}>
            {prismicData.buy_button_label.text}
          </a>
        </div>
        <div className={styles.contactUsContainer}>
          <p>{prismicData.custom_experience.text}</p>
          <LocalizedLink to={contactUsPath}>
            {prismicData.contact_us.text}
          </LocalizedLink>
        </div>
      </div>
    );
  }
}
